  
  /* GRID */
  
  .row {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }
  
  .row-middle {
    align-items: center;
  }
  
  .col {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }
  
  .col-start {
    justify-content: flex-start;
    text-align: left;
  }
  
  .col-center {
    justify-content: center;
    text-align: center;
  }
  
  .col-end {
    justify-content: flex-end;
    text-align: right;
  }
  
  
  /* Calendar */
  
  .calendar {
    display: block;
    position: relative;
    width: 100%;
  }
  
  .calendar .header {
    font-weight: 600;
    font-size: 115%;
    padding: 1.5em 0;
  }
  
  .calendar .header .icon {
    cursor: pointer;
    transition: .15s ease-out;
  }
  
  .calendar .header .icon:first-of-type {
    margin-left: 1em;
  }
  
  .calendar .header .icon:last-of-type {
    margin-right: 1em;
  }
  
  .calendar .days {
    font-weight: 500;
    font-size: 70%;
    padding: .75em 0;
  }
  
  .calendar .body .cell {
    position: relative;
    height: 5em;
    overflow: hidden;
    transition: 0.25s ease-out;
  }

  .calendar .body .mobile-cell {
    position: relative;
    height: 3em;
    overflow: hidden;
    transition: 0.25s ease-out;
  }
  
  .calendar .body .cell:hover {
    transition: 0.5s ease-out;
  }
  
  .calendar .body .selected-royal-day {
    background: rgba(134,164,255,0.2);    
    border-bottom: 10px solid #86a4ff;
  }

  .calendar .body .selected-blue-day {
    border-bottom: 10px solid #c1d0ff;
    background: rgba(193, 209, 255, 0.2);
  }

  .calendar .body .royal-day {
      border-bottom: 10px solid #86a4ff;
      background: rgba(0, 0, 0, 0.06);
  }
  .calendar .body .blue-day {
    border-bottom: 10px solid #c1d0ff;
    background: rgba(0, 0, 0, 0.06);
}
  
  .calendar .body .row:last-child {
    border-bottom: none;
  }
  
  .calendar .body .cell:last-child {
    border-right: none;
  }
  
  .calendar .body .cell .number {
    position: absolute;
    font-size: 82.5%;
    line-height: 1;
    top: .75em;
    right: .75em;
    font-weight: 700;
  }
  
  .calendar .body .disabled {
    color: rgb(140, 140, 140);
    pointer-events: none;
  }

  .calendar .body .weekend {
    color: #3182CE;
  }
  .calendar .body {
      background: rgba(0, 0, 0, 0.075);
  }
  
  .calendar .body .cell:hover .bg, .calendar .body .selected .bg  {
    opacity: 0.05;
    transition: .5s ease-in;
  }
  
  .calendar .body .col {
    flex-grow: 0;
    flex-basis: calc(100%/7);
    width: calc(100%/7);
  }